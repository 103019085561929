import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import { TcIconNotFound, TcIconError, TcIconServiceUnavailable } from '../IconSystem';
import { href_home as hrefHome } from '../href-helper';

import './style.less';

/**
 * 错误边界
 * 当没有任何错误的时候，会直接显示 children 的内容，否则隐藏 children 内容
 */
export const ErrorBoundary = (props) => {
    if (!props.error_type) {
        return props.children;
    }

    return (
        <Tag.div className="error-boundary">

            <Tag.div className="error-boundary-content">

                <Tag.div className="error-boundary-icon">
                    {props.error_type === ErrorBoundary.ERROR_TYPES.NOT_FOUND && <TcIconNotFound />}
                    {props.error_type === ErrorBoundary.ERROR_TYPES.FORBIDDEN && <TcIconError />}
                    {props.error_type === ErrorBoundary.ERROR_TYPES.SERVICE_UNAVAILABLE && <TcIconServiceUnavailable />}
                </Tag.div>

                <Tag.div className="error-boundary-msg">
                    {props.error_type === ErrorBoundary.ERROR_TYPES.NOT_FOUND && '你好像来到了吐槽星球的边界'}
                    {props.error_type === ErrorBoundary.ERROR_TYPES.FORBIDDEN && '没有查看权限，此内容仅管理员可见'}
                    {props.error_type === ErrorBoundary.ERROR_TYPES.SERVICE_UNAVAILABLE && '网络链接异常，请点击重试'}
                </Tag.div>

                <Tag.div className="error-boundary-button">

                    {props.error_type === ErrorBoundary.ERROR_TYPES.NOT_FOUND && <Tag.a className="btn" href={hrefHome()}>回到首页</Tag.a>}
                    {props.error_type === ErrorBoundary.ERROR_TYPES.FORBIDDEN && <Tag.a className="btn" href={hrefHome()}>回到首页</Tag.a>}
                    {props.error_type === ErrorBoundary.ERROR_TYPES.SERVICE_UNAVAILABLE && <Tag.a className="btn" href="javascript:;" onClick={event => window.location.reload()}>回到首页</Tag.a>}

                </Tag.div>

            </Tag.div>

        </Tag.div>
    );
};

ErrorBoundary.ERROR_TYPES = {
    NOT_FOUND: 'NOT_FOUND', // 404
    FORBIDDEN: 'FORBIDDEN', // 403
    SERVICE_UNAVAILABLE: 'SERVICE_UNAVAILABLE' // 503
};

ErrorBoundary.propTypes = {
    error_type: PropTypes.oneOfType([
        PropTypes.oneOf(Object.keys(ErrorBoundary.ERROR_TYPES)),
        PropTypes.bool
    ]),
    children: PropTypes.any,
};
