import React, { Component } from 'react';
import classNames from 'classnames';
import { StickyTool } from '@tencent/coral-design';
import BasePagePc from 'components/ui/layout/base-page-pc';
import { BreadCrumbs } from 'components/breadcrumbs';
import { PoweredBy } from 'components/poweredby';
import { href_blog_archive as hrefBlogArchive } from 'components/href-helper';
import { FooterSticky } from 'components/footer-sticky';
import { TcIconLiked, TcIconLikes, TcIconWarning } from 'components/IconSystem';
import {  getProductId, getContentId } from 'components/util';
import {
  get_api_teamblog as getApiTeamblog,
  get_api_teamblog_read as getApiTeamblogRead,
  post_api_v2_teamblog_like as postApiV2TeamblogLike,
  post_api_v2_teamblog_unlike as postApiV2TeamblogUnlike,
  post_api_v2_teamblog_liked as postApiV2TeamblogLiked,
} from 'components/api';
import { replaceAnchorHref } from 'components/render-rich-text';
import { ErrorBoundary } from 'components/error-boundary';
import PreviewImage from 'components/preview-image/preview-image-pc';

import 'components/skeleton/style.less';
import 'components/css/pc/reset.less';
import './style.less';


export class App extends Component {
  static getUrlParams() {
    const [productId, blogId] = location.pathname.match(/(\d+)\/blog\/(\d+)/);
    return { productId, blogId };
  }

  constructor(props) {
    super(props);

    this.state = {
      is_like: false,
      author_avatar: '',
      author_name: '',
      content: '',
      date: '',
      like_count: 0,
      read_count: 0,
      title: '',
      error_info: false,
      previewError: false,
      accountInfo: {},
    };

    this.productId = getProductId();
    this.blogId = getContentId();
  }

  componentDidMount() {
    const { productId, blogId } = this;

    getApiTeamblog({ productId, blogId })
      .then((resp) => {
        this.setState({ ...resp.data });
      })
      .catch((error) => {
        if (error.message === '博客不存在') {
          this.setState({ error_info: ErrorBoundary.ERROR_TYPES.NOT_FOUND });
        } else {
          this.setState({ previewError: true });
        }
      });

    getApiTeamblogRead({ productId, blogId }).catch(error => console.log(error));

    postApiV2TeamblogLiked({ productId, ids: [blogId] }).then((resp) => {
      if (!resp.data || !resp.data[blogId]) {
        return;
      }

      const { liked } = resp.data[blogId];

      this.setState({ is_like: liked });
    });
  }

  /**
     * 点赞按钮
     */
  onClickLike() {
    const { state, productId, blogId } = this;

    const { is_like: isLike } = state;

    if (!isLike) {
      postApiV2TeamblogLike({ productId, blogId });
      this.setState({
        is_like: true,
        like_count: state.like_count + 1,
      });
    } else {
      postApiV2TeamblogUnlike({ productId, blogId });
      this.setState({
        is_like: false,
        like_count: state.like_count - 1,
      });
    }
  }

  render() {
    const { state } = this;

    return (

      <BasePagePc
        getFaqs={true}
        hasBlogList={Boolean(state.title)}
        getAccountInfo={res => this.setState({ accountInfo: res.data })}
      >
        <div className="container">

          <BreadCrumbs content={[<a key="team" href={hrefBlogArchive()}>团队博客</a>, `${state.title}`]} />

          {!state.previewError && (
            <div className="main">

              <ErrorBoundary error_type={state.error_info}>
                <div className="article">

                  <h3 className="article-title skeleton-block">{state.title}</h3>

                  <div className="article-meta">

                    <div className="author-avatar">
                      <img src={state.author_avatar} alt={state.author_name} />
                    </div>

                    <div className="author-name skeleton-inline">{state.author_name}</div>

                    <span className="skeleton-inline">{state.date}</span>

                  </div>
                  <PreviewImage content={replaceAnchorHref(state.content)}>
                    <div className="article-content skeleton-multi" dangerouslySetInnerHTML={{ __html: replaceAnchorHref(state.content) }} />
                  </PreviewImage>
                </div>


                <div className="footer">
                  <div className="hr"></div>
                </div>

                <FooterSticky>

                  <div className="article-footer">

                    <div className="meta-like">
                      <div
                        className={classNames('meta-like-btn', { active: state.is_like })}
                        role="button" tabIndex="0"
                        onClick={() => this.onClickLike()}
                        onKeyDown={() => {}}
                      >
                        {state.is_like ? <TcIconLiked /> : <TcIconLikes />}{state.like_count} 赞
                      </div>
                    </div>

                    <div className="meta-read">
                      {state.read_count}人阅读
                    </div>

                  </div>

                </FooterSticky>
              </ErrorBoundary>

            </div>
          )}

          {state.previewError && (
            <div className="error-tip--container">
              <TcIconWarning />
              <h6 className="error-tip__h6">没有访问权限</h6>
              <p className="error-tip__p">非管理员无法查看预览模式</p>
            </div>
          )}

          <PoweredBy
            cid={state.accountInfo.cid}
            txEnterpriseUrl={`/enterprise/${state.accountInfo.cid}/home`}
          />

        </div>

        <StickyTool />
      </BasePagePc>

    );
  }
}
