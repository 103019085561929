/**
 * 匹配 /:num/xxx/:num 格式的URL
 * 适用
 * 1368/faqs/1234 常见问题详情
 * 1368/blog/1234 团队博客详情
 * 1368/post/1234 帖子详情
 */
export default () => {
    const matchRes = location.pathname.match(/\/(\d+)(\/[\w_-]+)\/([A-Za-z0-9]+)/i);
    return matchRes && matchRes[3];
};